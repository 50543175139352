.offerCardContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 0;
}

.offerCardContainer.adjustForBadge {
  height: calc(100% - var(--spacing-3));
  padding-top: var(--spacing-3);
}

/* Higher specificity needed when built+deployed. Using only .offerBadge works localhost but not deployed. Don't @ me */
div.offerBadge.offerBadge {
  height: 42px;
  margin: 0 auto 0 auto;
  margin-top: calc(calc(var(--spacing-5) * -1) - 2px);
  position: relative;
  top: var(--spacing-2);
  z-index: 2;
  width: fit-content;
  white-space: nowrap;
}

.offerAppUnselectedBadge.offerAppUnselectedBadge {
  align-self: flex-start;
  margin-bottom: var(--spacing-3);
}

.offerCard {
  border: 2px solid var(--color-gray2);
}

.offerCard.selected {
  border-color: var(--color-blue);
}

.offerCard.highlighted {
  border-color: var(--color-hallon2);
}

.offerCardMainContentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.priceDataWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: var(--spacing-3) 0;
}

.priceDataWrapper.priceDataWrapperSelected {
  flex-direction: column;
  justify-content: center;
}

.headerExtraInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
}

.headerExtraInfoWrapper.headerExtraInfoWrapperHidden {
  display: none;
}

.buttonUspWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
}

.buttonUspWrapper.buttonUspWrapperHidden {
  display: none;
}

.offerUspIconContainer {
  margin-bottom: var(--spacing-2);
}

.offerCardButton {
  margin-top: auto;
  margin-bottom: var(--spacing-2);
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (min-width: 768px) {
  .offerCardContainer,
  .offerCardContainer.adjustForBadge {
    padding-top: 0;
    height: 100%;
  }

  .offerCard {
    border: none;
  }

  .offerCard > div, .offerCard.selected > div {
    box-shadow: 0 0 0 2px var(--color-transparent);
  }

  .offerCard.highlighted > div {
    box-shadow: 0 0 0 2px var(--color-hallon2);
  }

  .offerCardMainContentWrapper {
    min-height: var(--offercard-highest-card-content-height);
  }

  .priceDataWrapper {
    flex-direction: column;
  }

  .priceDataWrapper.adjustForRegularSurf {
    min-height: 97px;
  }

  .headerExtraInfoWrapper,
  .headerExtraInfoWrapper.headerExtraInfoWrapperHidden,
  .buttonUspWrapper.buttonUspWrapper {
    display: flex;
  }

  .offerAppUnselectedBadge.offerAppUnselectedBadge {
    display: none;
  }
}
