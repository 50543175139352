.stickyOffer {
  animation: slideUp 0.25s ease-out forwards;
  background-color: var(--color-white);
  bottom: 0;
  box-shadow: var(--shadow-tabbar);
  left: 0;
  padding: var(--spacing-2);
  position: fixed;
  width: 100%;
  z-index: 949;
}

.stickySurf {
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
}

.stickyPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
